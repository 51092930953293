<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class OrganisationPayment extends Vue {}
</script>

<template>
  <div
    class="fusion-fullwidth fullwidth-box fusion-builder-row-2 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling"
    style="
      margin-top: 30px;
      margin-bottom: 30px;
      padding-top: 60px;
      padding-right: 30px;
      padding-bottom: 60px;
      padding-left: 30px;
      background-repeat: no-repeat;
      background-position: center center;
    "
  >
    <div class="fusion-builder-row fusion-row">
      <div
        class="
          fusion-layout-column
          fusion_builder_column
          fusion_builder_column_1_1
          fusion-builder-column-7
          fusion-one-full
          fusion-column-first
          fusion-column-last
          1_1
        "
        style="margin-top: 0; margin-bottom: 20px"
      >
        <div
          class="fusion-column-wrapper"
          style="height: auto; padding: 0; background-repeat: no-repeat; background-position: left top; background-size: cover"
          data-bg-url=""
        >
          <div
            class="
              fusion-title
              title
              fusion-title-6 fusion-sep-none fusion-title-center fusion-title-text fusion-title-size-three fusion-border-below-title
            "
            style="margin-top: 10px; margin-bottom: 15px"
          >
            <h3
              class="title-heading-center fusion-responsive-typography-calculated"
              style="--fontSize: 26; margin: 0; line-height: 1.3"
              data-fontsize="26"
              data-lineheight="33.8px"
            >
              Signup now and get your <span style="color: #e95a5d"><strong>1st MOTNH FREE</strong></span>
            </h3> </div
          ><div class="fusion-clearfix"></div>
        </div> </div
      ><div
        class="
          fusion-layout-column
          fusion_builder_column
          fusion_builder_column_1_1
          fusion-builder-column-8
          fusion-one-full
          fusion-column-first
          fusion-column-last
          1_1
        "
        style="margin-top: 0; margin-bottom: 20px"
      >
        <div
          class="fusion-column-wrapper"
          style="height: auto; padding: 0; background-repeat: no-repeat; background-position: left top; background-size: cover"
          data-bg-url=""
        >
          <div class="fusion-pricing-table pricing-table-1 sep-boxed-pricing row fusion-columns-1 columns-1 fusion-clearfix">
            <div class="panel-wrapper fusion-column column col-lg-12 col-md-12 col-sm-12 fusion-pricingtable-column">
              <div class="panel-container">
                <div class="fusion-panel">
                  <div class="panel-heading">
                    <h3
                      class="title-row fusion-responsive-typography-calculated"
                      data-fontsize="26"
                      data-lineheight="33.8px"
                      style="--fontSize: 26; line-height: 1.3"
                    >
                      Signup
                    </h3> </div
                  ><div class="panel-body pricing-row">
                    <div class="price"> <span class="currency"></span><span class="integer-part">FREE</span> </div> </div
                  ><ul class="list-group">
                    <li class="list-group-item normal-row"> Your first month’s check-ins for free </li>
                  </ul>
                </div>
              </div>
            </div> </div
          ><div class="fusion-clefix"></div>
        </div> </div
      ><div
        class="
          fusion-layout-column
          fusion_builder_column
          fusion_builder_column_1_1
          fusion-builder-column-9
          fusion-one-full
          fusion-column-first
          fusion-column-last
          1_1
        "
      >
        <div
          class="fusion-column-wrapper"
          style="height: auto; padding: 0; background-repeat: no-repeat; background-position: left top; background-size: cover"
          data-bg-url=""
        >
          <div class="fusion-pricing-table pricing-table-2 sep-boxed-pricing fusion-columns-4 fusion-clearfix flex flex-wrap">
            <div class="panel-wrapper fusion-column flex-col fusion-pricingtable-column">
              <div class="panel-container">
                <div class="fusion-panel">
                  <div class="panel-heading">
                    <h3
                      class="title-row fusion-responsive-typography-calculated"
                      data-fontsize="26"
                      data-lineheight="33.8px"
                      style="--fontSize: 26; line-height: 1.3"
                    >
                      Small
                    </h3> </div
                  ><div class="panel-body pricing-row">
                    <div class="price">
                      <span class="currency">R</span><span class="integer-part">375</span
                      ><span class="time price-without-decimal">monthly</span>
                    </div> </div
                  ><ul class="list-group">
                    <li class="list-group-item normal-row"> 0 – 250 Check-ins </li>
                  </ul>
                </div>
              </div> </div
            ><div class="panel-wrapper fusion-column flex-col fusion-pricingtable-column">
              <div class="panel-container">
                <div class="fusion-panel">
                  <div class="panel-heading">
                    <h3
                      class="title-row fusion-responsive-typography-calculated"
                      data-fontsize="26"
                      data-lineheight="33.8px"
                      style="--fontSize: 26; line-height: 1.3"
                    >
                      Medium
                    </h3> </div
                  ><div class="panel-body pricing-row">
                    <div class="price">
                      <span class="currency">R</span><span class="integer-part">1250</span
                      ><span class="time price-without-decimal">monthly</span>
                    </div> </div
                  ><ul class="list-group">
                    <li class="list-group-item normal-row"> 251 – 1000 Check-ins </li>
                  </ul>
                </div>
              </div> </div
            ><div class="panel-wrapper fusion-column flex-col fusion-pricingtable-column">
              <div class="panel-container">
                <div class="fusion-panel">
                  <div class="panel-heading">
                    <h3
                      class="title-row fusion-responsive-typography-calculated"
                      data-fontsize="26"
                      data-lineheight="33.8px"
                      style="--fontSize: 26; line-height: 1.3"
                    >
                      Large
                    </h3> </div
                  ><div class="panel-body pricing-row">
                    <div class="price">
                      <span class="currency">R</span><span class="integer-part">2000</span
                      ><span class="time price-without-decimal">monthly</span>
                    </div> </div
                  ><ul class="list-group">
                    <li class="list-group-item normal-row"> 1001 – 2000 Check-ins </li>
                  </ul>
                </div>
              </div> </div
            ><div class="panel-wrapper fusion-column flex-col fusion-pricingtable-column">
              <div class="panel-container">
                <div class="fusion-panel">
                  <div class="panel-heading">
                    <h3
                      class="title-row fusion-responsive-typography-calculated"
                      data-fontsize="26"
                      data-lineheight="33.8px"
                      style="--fontSize: 26; line-height: 1.3"
                    >
                      Enterprise
                    </h3> </div
                  ><div class="panel-body pricing-row">
                    <div class="price">
                      <span class="currency">R</span><span class="integer-part">3750</span
                      ><span class="time price-without-decimal">monthly</span>
                    </div> </div
                  ><ul class="list-group">
                    <li class="list-group-item normal-row"> 2001 – 5000 Check-ins </li>
                  </ul>
                </div>
              </div>
            </div> </div
          ><div class="fusion-clearfix"></div>
        </div> </div
      ><div
        class="
          fusion-layout-column fusion_builder_column fusion_builder_column_1_3 fusion-builder-column-10 fusion-one-third fusion-column-first
          1_3
        "
        style="width: 33.33%; width: calc(33.33% - ((4% + 4%) * 0.3333)); margin-top: 0; margin-right: 4%; margin-bottom: 20px"
      >
        <div
          class="fusion-column-wrapper"
          style="
            padding: 0 0 0 0;
            background-repeat: no-repeat;
            background-position: left top;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: er;
          "
          data-bg-url=""
        >
          <div class="fusion-clearfix"></div>
        </div> </div
      ><div
        class="fusion-layout-column fusion_builder_column fusion_builder_column_1_3 fusion-builder-column-11 fusion-one-third 1_3"
        style="width: 33.33%; width: calc(33.33% - ((4% + 4%) * 0.3333)); margin-top: 0; margin-right: 4%; margin-bottom: 20px"
      >
      </div>
    </div>
  </div>
</template>

<style type="text/css">
@media only screen and (max-width: 800px) {
  .fusion-title.fusion-title-6 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
}

.sep-boxed-pricing .panel-heading {
  padding: 15px;
  text-align: center;
  background-color: #66999b;
  background-clip: padding-box;
  border-color: #66999b;
  border-bottom: 1px solid #e5e4e3;
  border-radius: 0;
}
.pricing-table-1.fusion-pricing-table .panel-body .price .integer-part {
  color: #e95a5d;
}
.fusion-pricing-table .panel-body {
  padding: 15px;
  text-align: center;
}
.sep-boxed-pricing .panel-body .price .integer-part {
  font-size: 45px;
}
.fusion-pricing-table .panel-body .price .integer-part {
  display: inline;
  color: #66999b;
  font-weight: 700;
  line-height: normal;
}
.panel-wrapper {
  flex: 1 0 21%;
  margin: 5px;
}
.fusion-pricing-table .list-group-item {
  position: relative;
  display: block;
  margin: 0;
  padding: 15px;
  text-align: center;
  background: 0 0;
  border-top: 1px solid #e5e4e3;
  border-radius: 0;
}
</style>

<style type="text/css">
.pricing-table-1.full-boxed-pricing .panel-wrapper:hover .panel-heading,
.pricing-table-1 .panel-wrapper:hover .list-group-item {
  background-color: #f2f3f5;
}
.pricing-table-1.full-boxed-pricing .panel-heading {
  background-color: rgba(255, 255, 255, 0);
}
.pricing-table-1 .fusion-panel,
.pricing-table-1 .panel-wrapper:last-child .fusion-panel,
.pricing-table-1 .standout .fusion-panel,
.pricing-table-1 .panel-heading,
.pricing-table-1 .panel-body,
.pricing-tanel-footer {
  border-color: #e2e2e2;
}
.pricing-te-1l-body,
.pricing-table-1 .panel-footer {
  background-color: #f2f3f5;
}
.pricing-table-1.sep-boxed-pricing .panel-heading h3 {
  color: #fff;
}
.pricing-table-1.full-boxed-pricing.fusion-pricing-table .panel-heading h3 {
  color: #333;
}
.pricing-table-1.fusion-pricing-table .panel-body .price .decimal-part {
  color: #e95a5d;
}
.pricing-table-1.fusion-pricing-table .panel-body .price .integer-part {
  color: #e95a5d;
}
.pricing-table-1 ul.list-group li {
  color: #333;
}
</style>

<style type="text/css">
.pricing-table-2 .panel-container,
.pricing-table-2 .standout .panel-container,
.pricing-table-2.full-boxed-pricing {
  background-color: #f2f3f5;
}
.pricing-table-2 .list-group .list-group-item,
.pricing-table-2 .list-group .list-group-item:last-child {
  background-color: rgba(255, 255, 255, 0);
  border-color: #e2e2e2;
}
.pricing-table-2.full-boxed-pricing .panel-wrapper:hover .panel-heading,
.pricing-table-2 .panel-wrapper:hover .list-group-item {
  background-color: #f2f3f5;
}
.pricing-table-2.full-boxed-pricing .panel-heading {
  background-color: rgba(255, 255, 255, 0);
}
.pricing-table-2 .fusion-panel,
.pricing-table-2 .panel-wrapper:last-child .fusion-panel,
.pricing-table-2 .standout .fusion-panel,
.pricing-table-2 .panel-heading,
.pricing-table-2 .panel-body,
.pricing-tabll-footer {
  border-color: #e2e2e2;
}
.pricing-tanel-body,
.pricing-table-2 .panel-footer {
  background-color: #f2f3f5;
}
.pricing-table-2.sep-boxed-pricing .panel-heading h3 {
  color: #fff;
}
.pricing-table-2.full-boxed-pricing.fusion-pricing-table .panel-heading h3 {
  color: #333;
}
.pricing-table-2.fusion-pricing-table .panel-body .price .decimal-part {
  color: #66999b;
}
.pricing-table-2.fusion-pricing-table .panel-body .price .integer-part {
  color: #66999b;
}
.pricing-table-2 ul.list-group li {
  color: #333;
}
</style>

<style type="text/css">
.fusion-button.button-1 {
  border-radius: 10px;
}
</style>
